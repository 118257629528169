<template>
  <div>
    <div>
      <div>{{ formData }}</div>
      <div>{{ productForm }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormCompareView',
  props: {},
  data() {
    return {
      //审核表单信息
      formData: {},
      //商品信息
      productForm: {}
    }
  },
  methods: {
    show(formData) {
      this.formData = { ...formData }
    }
  }
}
</script>

<style scoped>

</style>
